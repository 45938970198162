import React from 'react'
import Link from './link'

export default function NextProject({ next }) {


    function animateBody (color) {
        if(window.innerWidth > 768){
            document.body.style.backgroundColor = color ;
            document.querySelectorAll(".gallery--image.multiply")?.forEach((image) =>{
                image.style.backgroundColor = color ;
            })
        }
    }

    function rAnimateBody () {
        document.body.style.backgroundColor = 'inherit';
        document.querySelectorAll(".gallery--image.multiply")?.forEach((image) =>{
            image.style.backgroundColor = '#F6EFE0' ;
        })
    }
    
    return (
        <div className='flex justify-center'>
            <Link onMouseLeave={rAnimateBody} onMouseEnter={() => animateBody(next.projectColour?.hex)} className='link ul-link ma uppercase spacing med m-xs' to={`/portfolio/${next.slug}`}>Next Project</Link>
        </div>
    )
}
