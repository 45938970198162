import * as React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

const Gallery = ({gallery}) => {
  const images = gallery.map((image, index) => {
    const removeBackground = image.mixBlendMultiply
    if(!image.secondaryImage){
      return (
        <div data-sal className={image.subtitle ? 'gallery--image p40 fade--in with-caption ' : 'gallery--image fade--in p40 ' + (removeBackground ? ' multiply ' : ' no-multiply ' )  } key={index}>  
          <GatsbyImage className={  ( (image.imageSize ? 'size-'+image.imageSize.toLowerCase() : null))} image={image.image ? image.image.gatsbyImageData : null} alt="Gallery Image" />
          <div className={image.subtitle ? 'caption mt10' : 'hide'}>{image.subtitle}</div>
        </div>
      ) 
    } else {
      return (
        <div className={"gallery--image flex fade--in "  +  (removeBackground ? ' multiply ' : ' no-multiply ' ) } key={index} data-sal>
          <div className="w-50 p40 flex">
            <GatsbyImage className={image.imageSize ? 'h-100 size-'+image.imageSize.toLowerCase() : 'h-100'} image={image.image.gatsbyImageData} alt="Gallery Image" />  
          </div>
          <div className="w-50 p40 flex">
            <GatsbyImage className={image.imageSize ? 'h-100 size-'+image.secondaryImageSize.toLowerCase() : 'h-100'} image={image.secondaryImage.gatsbyImageData} alt="Gallery Image" /> 
          </div>
        </div>
      )
    }
  })
  return (
    <div className="gallery text-center max-1600 ma mt40 mb40">
      {images}
    </div> 
  )
} 
export default Gallery
