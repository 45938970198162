import { graphql } from 'gatsby'
import React from 'react'
import Layout from "../components/layout"
import FeaturedBanner from "../components/featuredBanner"
import Gallery from "../components/gallery" 
import SEO from "../components/seo"
import NextProject from '../components/nextProject'
export default function Portfolio({ data }) {
    return (
        <Layout>
            <SEO title={data.datoCmsPortfolio.title} />
            <FeaturedBanner title={data.datoCmsPortfolio.title} image={data.datoCmsPortfolio.heroImage} />
            <Gallery gallery={data.datoCmsPortfolio.gallery} />
            <NextProject next={data.next} />
        </Layout>
    )
}

export const query = graphql`
    query Portfolio($id :String!,$next : String!) {
        datoCmsPortfolio(id:{eq:$id}){
            title
            heroImage {
                gatsbyImageData(placeholder:NONE)
            }
            gallery {
                imageSize
                splitImage
                subtitle
                secondaryImageSize
                mixBlendMultiply              
                image {
                  gatsbyImageData(placeholder:NONE)
                }
                secondaryImage {
                  gatsbyImageData(placeholder:NONE)
                }
            }
        }
        next:datoCmsPortfolio(id:{eq:$next}){
            title
            projectColour {
                hex
            }
            slug
            heroImage {
                gatsbyImageData(placeholder:NONE)
            }
        }
    }
`