import * as React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

const ImageText = ({image, title}) => {
  const imageHover = () =>  { 
    document.querySelector('.featured--banner .title.above').classList.add('fade-out');
  }
  const imageHoverOut = () =>  { 
    document.querySelector('.featured--banner .title.above').classList.remove('fade-out'); 
  }
  return (
  <div className="featured--banner mt40 m-mt80 p40 max-1400 ma text-center"> 
    <div className="featured--image">
      <GatsbyImage  image={image.gatsbyImageData} alt='Hero Image' onMouseOver={(event) => imageHover(event)}  onMouseOut={(event) => imageHoverOut(event)} /> 
    </div>
    <h1 className="m0 title above ">{title}</h1>
    <h1 className="m0 title below m-hide">{title}</h1>
  </div> 
  ) 
}
export default ImageText
